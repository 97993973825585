import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class AppConfig{
    public baseApiUrl:string;
    public appName:string;
    public basecolor:string; //for map api
    public platform:string; ///for decide the platform either 'browser' or 'android'
    constructor(){
        this.baseApiUrl = "https://api.vaagaibus.com/api";
        this.appName = "Vaagai GPS";
        this.basecolor = "#7dbe48";
        this.platform = 'browser';
    }
}