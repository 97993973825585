import { Injectable } from '@angular/core';
import { AppConfig } from '../app.config';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { ToastService } from './app.toastservice';
import { from } from 'rxjs';    
import { NetworkService, ConnectionStatus } from './network.service';
import { debounceTime } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class HttpServices{

    loading:any; 

    constructor(
        private http:HttpClient,
        private config:AppConfig, 
        private loadingcontroller:LoadingController,
        private network: NetworkService,
        private toast:ToastService
        ){
    
    }

    async getResponse(apiUrl:any,apipostvalue:any,token:string,method,ishideloading,isOwnAPI = true) {
        if(isOwnAPI){
            apiUrl = this.config.baseApiUrl+apiUrl;
        }
        if(this.network.getCurrentNetworkStatus() == ConnectionStatus.Online || this.config.platform == 'browser'){
            // do nothing
        }else{
            this.toast.presentToast("No Network Connection Available.So kindly Check your Network connection")
            return;
        }

        if(!ishideloading){
            this.loading = await this.loadingcontroller.create({
                message: 'Loading....'
            });
            await this.loading.present();
        }
        
        if(method !== 'post'){
            return new Promise((resolve, reject) => {
                this.http.get(apiUrl).subscribe(data => {
                    resolve(data);
                    this.hideLoading();
                }, err => {
                    reject(err);
                    this.hideLoading();
                });
            });
        }else{
            return new Promise((resolve, reject) => {
                let option = new HttpHeaders().append('Content-Type', 'application/json');
                if(token !== ''){
                    option = option.append('token',token);
                }
                let postData = (apipostvalue!=='')?JSON.stringify(apipostvalue):{}
                this.http.post(apiUrl,postData,{headers:option}).subscribe(res => {
                    resolve(res);
                    this.hideLoading();
                  }, (err) => {
                    reject(err);
                    this.hideLoading();
                  });
            });
        }   
    }

    hideLoading(){
        if(this.loading){
            this.loading.dismiss();
        }
    }
    
}