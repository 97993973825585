import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DataSharedService } from './app.datasharedservice';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {
 constructor(private router:Router){

 } 

 canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    //return new Promise((resolve) => {
      if(new DataSharedService().getToken() != '' && typeof new DataSharedService().getToken() !== 'undefined' && new DataSharedService().getToken() !== null){
        return true;
      }else{
        this.router.navigateByUrl('/login');
        return false;
      }
    //});
  }
}
