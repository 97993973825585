import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-pwrcutofcnfrm',
  templateUrl: './pwrcutofcnfrm.component.html',
  styleUrls: ['./pwrcutofcnfrm.component.scss'],
})
export class PwrcutofcnfrmComponent implements OnInit {
  @Input() message;
  @Input() popoverpage;

  constructor(
    popover:PopoverController
  ) { 
   
  }

  ngOnInit() {}

  public popUpClose(mes){
    this.popoverpage.dismiss(mes);
  }
}
