import { Component } from '@angular/core';
import { Platform,MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppConfig } from './app.config';
import { Router, NavigationEnd } from '@angular/router';
//import { FCM } from '@ionic-native/fcm/ngx';
//import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { DataSharedService } from './services/app.datasharedservice';
import { HttpServices } from './services/app.httpservice';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appName:string;
  public appPages = [
    {
      title: 'Vehicle Tracking',
      url: '/home',
      icon: 'trackiconblk.png'
    },
    {
      title: 'Vehicle List',
      url: '/vehiclelist',
      icon: 'vehiclelist.png'
    },
    {
      title: 'Reports',
      icon:'reportsicon.png',
      subpages:[
        {
          title: 'Distance Report',
          url: '/kmreport',
          icon: 'distanceicon.png'
        },
        {
          title: 'Engine Hours Report',
          url: '/runninghrsrpt', 
          icon: 'timeicon.png'
        },{
          title:'Engine On Off Report',
          url:'/engineonoffreport',
          icon:'enginereport.png'
        },{
          title:'OverSpeed Report',
          url:'/vehicleoverspeedreport',
          icon:'overspeed.png'
        }
      ]
    },
    {
      title:'Engine Remote Power CutOff',
      url: '/remotepwrctoff',
      icon: 'enginepwrctof.jpg'
    },/*
    {
      title:'Settings',
      url: '/settings',
      icon: 'settings.png'
    },*/
    {
      title: 'LogOut',
      url: '/logout',
      icon: 'logout.png'
    }
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private appConfig: AppConfig,
    //private fcm: FCM,
    private router: Router,
  //  private localNotifications: LocalNotifications,
    private dataSharedService: DataSharedService,
    private http:HttpServices,
    private push: Push,
    private menuCtrl:MenuController,
    private backgroundMode: BackgroundMode
  ) {
    this.initializeApp();
    this.appName = this.appConfig.appName;
    this.platform.backButton.subscribe(()=>{
      if(this.router.url === '/home'){
        navigator['app'].exitApp();
      }else if(this.router.url ==='/login'){
        navigator['app'].exitApp();
      }else if(this.router.url ==='/logout'){
        navigator['app'].exitApp();
      }else{
        this.router.navigateByUrl('/home');
      }
    })
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.backgroundMode.setDefaults({
        silent: true
    })
      this.backgroundMode.enable();
      this.statusBar.overlaysWebView(true);
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.pushNotification();
      this.dataSharedService.setIsSendFcmToken(true);
    });
  }

  closeMenu(){
    this.menuCtrl.close();
  }

  /*fcmintialization(){
    
    this.fcm.getToken().then(token => {
      this.dataSharedService.setFcmToken(token);
    });

    this.fcm.onTokenRefresh().subscribe(token => {
      this.dataSharedService.setFcmToken(token);
    });
    
    this.fcm.onNotification().subscribe(data => {
      if (data.wasTapped) {
        this.router.navigate([data.landing_page, data.price]);
      } else {
        this.localNotifications.schedule({
          id: 1,
          text: data.body,
          title: data.title
        });
      }
    });
  }*/

  pushNotification(){
    // to check if we have permission
    this.push.hasPermission()
    .then((res: any) => {

      if (res.isEnabled) {
        console.log('We have permission to send push notifications');
      } else {
        console.log('We do not have permission to send push notifications');
      }

    });

    // Create a channel (Android O and above). You'll need to provide the id, description and importance properties.
    this.push.createChannel({
      id: "vehiclestarted",
      description: "for intimating vehicle started",
      sound:"vehiclestarted",
      // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
      importance: 3
    }).then(() => console.log('Channel created'));

    this.push.createChannel({
      id: "vehiclestoped",
      description: "for intimating vehicle stoped",
      sound:"vehiclestoped",
      // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
      importance: 3
    }).then(() => console.log('Channel created'));

    this.push.createChannel({
      id: "vehicleoverspeed",
      description: "for intimating vehicle overspeed",
      sound:"vehicleoverspeed",
      // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
      importance: 3
    }).then(() => console.log('Channel created'));

    this.push.createChannel({
      id: "engpwrrstd",
      description: "for intimating vehicle enginepower restored",
      sound:"engpwrrstd",
      // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
      importance: 3
    }).then(() => console.log('Channel created'));

    this.push.createChannel({
      id: "engpwrcutoff",
      description: "for intimating vehicle enginepower cut off",
      sound:"engpwrcutoff",
      // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
      importance: 3
    }).then(() => console.log('Channel created'));

    this.push.createChannel({
      id: "devicepowercut",
      description: "for intimating gps device power cut off",
      sound:"devicepowercut",
      // The importance property goes from 1 = Lowest, 2 = Low, 3 = Normal, 4 = High and 5 = Highest.
      importance: 3
    }).then(() => console.log('Channel created'));

    const options: PushOptions = {
      android: {
        sound: true,
        vibrate: true,
        clearBadge: true,
        icon: 'icon',
        clearNotifications: true,forceShow : true,
      },
      ios: {
          alert: 'true',
          badge: true,
          sound: 'true'
      },
      windows: {},
      browser: {
        pushServiceURL: 'http://push.api.phonegap.com/v1/push'
      }
    }
   
    const pushObject: PushObject = this.push.init(options);
    this.dataSharedService.setPushNotificationObject(pushObject);

    pushObject.on('notification').subscribe((notification: any) => {
      if(notification.additionalData.foreground){
        //foreground
        /*this.localNotifications.schedule({
          id: 1,
          text: notification.message,
          title: notification.title
        });*/
      }else{//background
        this.router.navigateByUrl('/home');
      }
    });
    
    pushObject.on('registration').subscribe((registration: any) => {
      this.dataSharedService.setFcmToken(registration.registrationId);
    });

    pushObject.on('error').subscribe(error => console.error('Error with Push plugin', error));
  }
}
