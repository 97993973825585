import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { Network } from '@ionic-native/network/ngx';
import { GoogleMaps } from '@ionic-native/google-maps';
import { DatePipe } from '@angular/common';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
//import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { PwrcutofcnfrmComponent } from './popup/pwrcutofcnfrm/pwrcutofcnfrm.component';
import { Geocoder } from '@ionic-native/google-maps';
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { NativeGeocoder } from "@ionic-native/native-geocoder/ngx";
import { Push } from "@ionic-native/push/ngx";
import { HomePageProfilepopupComponent } from './popup/home-page-profilepopup/home-page-profilepopup.component';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { File } from '@ionic-native/file/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import * as L from 'leaflet';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';

//import { IonRangeSliderModule } from "ng2-ion-range-slider";

//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { TooltipsModule } from 'ionic4-tooltips';

@NgModule({
  declarations: [AppComponent,PwrcutofcnfrmComponent,HomePageProfilepopupComponent],
  entryComponents: [PwrcutofcnfrmComponent,HomePageProfilepopupComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    //BrowserAnimationsModule,
    //TooltipsModule.forRoot()
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    LocationAccuracy,BackgroundMode,
    Network,GoogleMaps,DatePipe,LaunchNavigator,Push/*,LocalNotifications*/,PwrcutofcnfrmComponent,Geocoder,SocialSharing,NativeGeocoder,FileChooser,File,/*IonRangeSliderModule,*/Geolocation,AndroidPermissions
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
