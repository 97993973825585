import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { DataSharedService } from '../../services/app.datasharedservice';
import { Router, NavigationEnd } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { HttpServices } from '../../services/app.httpservice';

@Component({
  selector: 'app-home-page-profilepopup',
  templateUrl: './home-page-profilepopup.component.html',
  styleUrls: ['./home-page-profilepopup.component.scss'],
})
export class HomePageProfilepopupComponent implements OnInit {
  operatorName:String;
  @Input() popoverpage;
  constructor(
    private dataService:DataSharedService,
    private router:Router,
    private http:HttpServices
  ) {
    this.operatorName = this.dataService.getOperatorName();
  }

  ngOnInit() {}

  public goTOProfile(){
    this.popoverpage.dismiss();
    this.router.navigateByUrl('/profile');
  }

  public goTOChangePassword(){
    this.popoverpage.dismiss();
    this.router.navigateByUrl('/changepassword');
  }

  public logOut(){
    let url = '/LogOut';
    let postdata = {};
    postdata['fcmtoken'] = this.dataService.getFcmToken();
    let token = this.dataService.getToken();
    this.http.getResponse(url,postdata,token,'post',false).then(data => {
    });
    this.dataService.setToken('');
    this.dataService.setOperatorName('');
    this.dataService.setVehicleList('');
    this.dataService.setIsSendFcmToken(true);
    this.popoverpage.dismiss();
    this.router.navigateByUrl('/login');
  }

}
 