import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DataSharedService } from './services/app.datasharedservice';
import { AuthGuardService } from './services/auth.guard';

let routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'home', loadChildren: './pages/home/home.module#HomePageModule', canActivate:[AuthGuardService] },
  { path: 'logout', loadChildren: './pages/logout/logout.module#LogoutPageModule', canActivate:[AuthGuardService] },
  { path: 'kmreport', loadChildren: './pages/kmreport/kmreport.module#KmreportPageModule', canActivate:[AuthGuardService] },
  { path: 'vehiclelist', loadChildren: './pages/vehiclelist/vehiclelist.module#VehiclelistPageModule', canActivate:[AuthGuardService] },
  { path: 'engineonoffreport', loadChildren: './pages/engineonoffreport/engineonoffreport.module#EngineonoffreportPageModule', canActivate:[AuthGuardService] },
  { path: 'remotepwrctoff', loadChildren: './pages/remotepwrctoff/remotepwrctoff.module#RemotepwrctoffPageModule', canActivate:[AuthGuardService] },
  { path: 'runninghrsrpt', loadChildren: './pages/runninghrsrpt/runninghrsrpt.module#RunninghrsrptPageModule', canActivate:[AuthGuardService] },
  { path: 'vehicledetails/:vehicleno', loadChildren: './pages/vehicledetails/vehicledetails.module#VehicledetailsPageModule', canActivate:[AuthGuardService] },
  { path: 'travelledroutes/:vehicleno', loadChildren: './pages/travelledroutes/travelledroutes.module#TravelledroutesPageModule', canActivate:[AuthGuardService] },
  { path: 'editvehicleinfo/:vehicleno', loadChildren: './pages/editvehicleinfo/editvehicleinfo.module#EditvehicleinfoPageModule', canActivate:[AuthGuardService] },
  { path: 'vehiclenotification/:vehicleno', loadChildren: './pages/vehiclenotification/vehiclenotification.module#VehiclenotificationPageModule', canActivate:[AuthGuardService] },
  { path: 'enginehrsdtldreport/:vehicleno', loadChildren: './pages/enginehrsdtldreport/enginehrsdtldreport.module#EnginehrsdtldreportPageModule', canActivate:[AuthGuardService] },
  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule', canActivate:[AuthGuardService] },
  { path: 'profile', loadChildren: './pages/profile/profile.module#ProfilePageModule', canActivate:[AuthGuardService] },
  { path: 'changepassword', loadChildren: './pages/changepassword/changepassword.module#ChangepasswordPageModule', canActivate:[AuthGuardService] },
  { path: 'documents/:vehicleno', loadChildren: './pages/documents/documents.module#DocumentsPageModule', canActivate:[AuthGuardService] },
  { path: 'vehicleoverspeedreport', loadChildren: './pages/vehicleoverspeedreport/vehicleoverspeedreport.module#VehicleoverspeedreportPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
