import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

export class DataSharedService{

    vehicleList;
    issendFcmToken:boolean = false;
    /*for storing the from date time and to date time in vehicle detail page mainly used for report from vrhicle details */
    fromDateTime;
    toDateTime;
    pushobj;
    imageasbase64;

    constructor(){
       
    }

    setToken(token:any){
        localStorage.setItem('operatorauthtoken',token);
    }
    getToken(){
        return localStorage.getItem('operatorauthtoken');
    }
    setOperatorName(name:string){
        localStorage.setItem('operatorName',name);
    }
    getOperatorName(){
        return localStorage.getItem('operatorName');
    }
    setVehicleList(vehiclelist){
        localStorage.setItem('vehicleList',JSON.stringify(vehiclelist));
    }
    getVehicleList(){
        return JSON.parse(localStorage.getItem('vehicleList') || "[]");
    }
    setFcmToken (token:any){
        localStorage.setItem('fcmtkn',token);
    }
    getFcmToken(){
        return localStorage.getItem('fcmtkn');
    }
    setIsSendFcmToken(value){
        this.issendFcmToken = value;
    }
    getIsSendFcmToken(){
        return this.issendFcmToken;
    }
    getFromDateTime(){
        return this.fromDateTime;
    }
    setFromDateTime(value){
        this.fromDateTime = value;
    }
    getToDateTime(){
        return this.toDateTime;
    }
    setTODateTIme(value){
        this.toDateTime = value;
    }
    getVehicleDetails(vehicleNo){
        return JSON.parse(localStorage.getItem('vehicleList') || "[]").filter(vehicle=>{return vehicle.vehicleno === vehicleNo})[0];
    }
    setMapType(value){
        localStorage.setItem('maptype',value);
    }
    getMapType(){
        return localStorage.getItem('maptype');
    }
    setPushNotificationObject(value){
        this.pushobj = value;
    }
    getPushNotificationObject(){
        return this.pushobj;
    }
    setImageasbase64(value){
        this.imageasbase64 = value;
    }
    getImageasbase64(){
        return this.imageasbase64;
    }
}